<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="400px"
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="card-icon align-self-end mx-1"
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="success"
            bordered
            overlap
            light
            :content="selected"
            :value="selected"
          >
            <v-icon medium color="grey" dark v-bind="attrs" v-on="on">
              mdi-filter-menu
            </v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Busca Personalizada</v-card-title>
        <v-divider></v-divider>
        <v-card-actions style="flex-direction: row-reverse">
          <v-btn
            class="button1"
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Filtrar
          </v-btn>
        </v-card-actions>
        <v-card-text justify="center" align="center">
          <v-row
            v-if="$user.get().role === 'admin' || $user.get().role === 'gestor'"
            class="px-4"
            justify="center"
            align="center"
          >
            <v-col cols="12">
              <v-autocomplete
                v-model="form.consultor"
                multiple
                clearable
                chips
                deletable-chips
                autocomplete="autocomplete_off_hack_xfr4!k"
                item-text="Nome"
                item-value="id"
                label="Consultor"
                validate-on-blur
                :loading="loading.consultor"
                :disabled="loading.consultor"
                :items="consultores"
                @change="isSelect()"
              />
            </v-col>
          </v-row>
          <v-row class="px-4" justify="center" align="center">
            <v-col cols="12">
              <v-autocomplete
                v-model="form.agroindustria"
                multiple
                clearable
                chips
                deletable-chips
                autocomplete="autocomplete_off_hack_xfr4!k"
                item-text="name"
                item-value="id"
                label="Agroindústrias"
                validate-on-blur
                :loading="loading.agroindustria"
                :disabled="loading.agroindustria"
                :items="agroindustrias"
                @change="isSelect()"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { _orderByName } from '../../utils/formatter'
export default {
  name: 'FilterDialog',

  data() {
    return {
      dialogm1: '',
      dialog: false,

      selected: 0,

      loading: {
        consultor: true,
        agroindustria: true,
      },

      form: {
        consultor: '',
        agroindustria: '',

        consultorBox: false,
        agroindustriaBox: false,
      },

      agroindustrias: [],
      consultores: [],
    }
  },

  watch: {
    dialog: function () {
      if (!this.dialog) {
        let consultores = []
        let agroindustrias = []

        if (this.form.consultor) {
          this.form.consultor.forEach(item => {
            const consultor = this.consultores.find(
              consultor => consultor.id === item,
            )
            consultores.push(consultor.id)
          })
        }

        if (this.form.agroindustria) {
          this.form.agroindustria.forEach(item => {
            const agroindustria = this.agroindustrias.find(
              agroindustria => agroindustria.id === item,
            )
            agroindustrias.push(agroindustria.id)
          })
        }

        this.$emit('filter', {
          consultor: this.form.consultorBox ? consultores : null,
          agroindustria: this.form.agroindustriaBox ? agroindustrias : null,
        })

        this.countSelectedBox()
      }
    },
  },

  mounted() {
    this.$root.$on('searchingFilter', name => {
      this.dialogm1 = name

      this.selected = 0

      this.form.consultorBox = false
      this.form.agroindustriaBox = false
      this.form.consultor = ''
      this.form.agroindustria = ''
    })
  },

  created() {
    this.getAgroindustrias()
    this.getConsultores()
  },

  methods: {
    isSelect() {
      this.form.consultorBox = false
      this.form.agroindustriaBox = false
      if (this.form.consultor != '') this.form.consultorBox = true
      if (this.form.agroindustria != '') this.form.agroindustriaBox = true
    },

    countSelectedBox() {
      this.selected = 0

      if (this.form.consultorBox) this.selected++
      if (this.form.agroindustriaBox) this.selected++
    },

    getConsultores() {
      if (
        this.$user.get().role === 'admin' ||
        this.$user.get().role === 'gestor'
      ) {
        this.api.get.consultores().then(data => {
          this.consultores = _orderByName({
            data,
            key: 'Nome',
          })
          this.loading.consultor = false
        })
      } else {
        this.loading.consultor = false
      }
    },

    getAgroindustrias() {
      this.api.get.agroindustrias().then(data => {
        this.agroindustrias = data
        this.agroindustrias = this.agroindustrias.filter(item => {
          return item.pessoa?.pessoajuridica?.NomeFantasia
        })
        this.agroindustrias = this.agroindustrias.map(item => {
          return {
            name: item.pessoa.pessoajuridica.NomeFantasia,
            id: item.CodAgroindustria,
          }
        })

        this.agroindustrias = _orderByName({
          data: this.agroindustrias,
          key: 'name',
        })
        this.loading.agroindustria = false
      })
    },
  },
}
</script>

<style scoped>
.button1 {
  background-color: white;
  color: black;
  border: 2px solid #4caf50; /* Green */
  border-radius: 4px;
  width: 10%;
}
</style>
