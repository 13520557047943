<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-card class="card-success" style="min-height: 60vh">
          <h4 slot="header" class="card-title text-center">
            Associação do supervisor
          </h4>
          <div class="clearfix" />
          <div>
            <v-row align="center" style="margin-left: 5px; margin-right: 5px">
              <v-col cols="8">
                <v-autocomplete
                  v-model="farmsCods"
                  solo
                  multiple
                  small-chips
                  clearable
                  :items="farms"
                  :loading="loadingFarms"
                  item-text="text"
                  item-value="value"
                  name="fazendas"
                  label="Selecione *"
                  @change="update = true"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 8" small class="caption">
                      <span>
                        {{ item.text }}
                      </span>
                    </v-chip>
                    <span v-if="index === 8" class="grey--text caption">
                      (+{{ farmsCods.length - 8 }} outros)
                    </span>
                  </template>
                  <template slot="prepend-item" style="ml-8 mr-8">
                    <v-btn
                      class="blue white--text"
                      elevation="2"
                      color="blue"
                      block
                      small
                      @click="selectAll"
                    >
                      SELECIONAR TODOS
                    </v-btn>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="2">
                <filter-dialog @filter="filter" />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      elevation="2"
                      class="blue darken-1 white--text"
                      :disabled="!farmsCods"
                      :loading="loadingBottom"
                      v-bind="attrs"
                      @click="getFarm"
                      v-on="on"
                    >
                      <span class="mdi mdi-arrow-down-bold mdi-24px"></span>
                    </v-btn>
                  </template>
                  <span>Adicionar</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </div>
          <crud-list v-model="centrals" :headers="headers" :actions="actions">
            <template v-slot:btnAddFazenda>
              <v-btn
                elevation="2"
                color="success"
                :disabled="!update"
                :loading="loadingBottom"
                @click="updateAssociation"
                >Salvar alterações</v-btn
              >
            </template>
          </crud-list>
        </v-card>
      </template>
    </div>
  </v-card>
</template>
<script>
import { _orderByName } from '../../utils/formatter'
import FilterDialog from './filter.vue'
export default {
  components: { FilterDialog },

  data() {
    return {
      id: '',
      update: false,
      loading: true,
      loadingBottom: false,
      selectedAll: false,

      requiredRules: [v => !!v || '* Obrigatório'],

      farms: [],
      loadingFarms: true,
      farmsCods: '',
      data: [],

      actions: [
        {
          title: 'Deletar fazenda',
          color: 'red darken-3',
          click: item => this.removeFarm(item),
          icon: 'mdi-delete',
        },
      ],

      centrals: [],
      headers: [
        {
          text: '',
          sortable: false,
          width: '80px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Fazenda',
          align: 'left',
          sortable: true,
          value: 'fazenda',
          width: 'auto',
        },
        {
          text: 'Consultor',
          align: 'left',
          sortable: true,
          value: 'consultor',
          width: 'auto',
        },
        {
          text: 'Produtor',
          align: 'left',
          sortable: true,
          value: 'produtor',
          width: 'auto',
        },
      ],
    }
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
      this.loadFazendas().then(() => {
        this.loadAssociation()
      })
    }
  },
  methods: {
    filter(data) {
      let res = this.data
      let resposta = []
      let fazendasAgroindustria = []
      let fazendasConsultor = []

      if (data.agroindustria) {
        data.agroindustria.forEach(id => {
          let fazendas = res.filter(
            item => item?.agroindustria_ativa?.CodAgroindustria === id,
          )
          fazendasAgroindustria = fazendasAgroindustria.concat(fazendas)
        })
      }

      if (!fazendasAgroindustria.length && !data.agroindustria) {
        fazendasAgroindustria = res
      }

      if (data.consultor) {
        data.consultor.forEach(id => {
          let fazendas = fazendasAgroindustria.filter(
            item => item?.consultor?.CodPessoaFisica === id,
          )
          fazendasConsultor = fazendasConsultor.concat(fazendas)
        })
      }

      if (!fazendasConsultor.length && !data.consultor) {
        fazendasConsultor = fazendasAgroindustria
      }

      Object.assign(resposta, fazendasConsultor)

      this.farms = resposta
    },
    loadFazendas() {
      return new Promise((resolve, reject) => {
        this.api.get
          .fazendas()
          .then(data => {
            this.farms = data

            this.farms = this.farms.map(item => {
              return {
                text: item.pessoajuridica.NomeFantasia,
                value: item.CodFazenda,
                ...item,
              }
            })

            this.data = this.farms
            this.farms = _orderByName({
              data: this.farms,
              key: 'text',
            })

            this.loadingFarms = false

            return resolve()
          })
          .catch(err => {
            this.loadingFarms = false
            return reject(err)
          })
      })
    },

    loadAssociation() {
      this.api.get.getAssociation(this.id).then(res => {
        const list = []

        res.forEach(item => {
          const r = this.farms.find(
            farm => farm?.CodFazenda === item.CodFazenda,
          )

          if (!r) return

          list.push({
            id: r.CodFazenda,
            fazenda: r.pessoajuridica.NomeFantasia,
            consultor: r.consultor.Nome,
            produtor: r.produtor.Nome,
          })
        })

        this.centrals = list
        this.loading = false
      })
    },

    getFarm() {
      if (this.selectedAll) {
        let array = []

        this.farmsCods.forEach(function (item) {
          array.push(item.CodFazenda)
        })

        array.forEach(farmCod => {
          this.addFarm(farmCod)
        })
      } else {
        this.farmsCods.forEach(farmCod => {
          this.addFarm(farmCod)
        })
      }
    },

    addFarm(farmCod) {
      const exists = this.centrals.find(farm => farm.id === farmCod)

      if (exists || this.farmsCods === ' ') return

      const farm = this.farms.find(farm => farm.CodFazenda === farmCod)

      this.centrals.unshift({
        id: farm.CodFazenda,
        fazenda: farm.pessoajuridica.NomeFantasia,
        consultor: farm.consultor.Nome,
        produtor: farm.produtor.Nome,
      })
      this.selectedAll = false
      this.update = true
    },

    removeFarm(item) {
      const find = this.centrals.find(farm => farm.id === item.id)
      this.centrals.splice(this.centrals.indexOf(find), 1)
      this.update = true
    },

    updateAssociation() {
      this.loadingBottom = true
      this.farmsCods = ''
      this.update = false
      const farms = this.centrals.map(farm => {
        return farm.id
      })

      this.api.update
        .association(this.id, { farms })
        .then(() => {
          this.Swal.fire(
            'Sucesso',
            'Fazendas atualizadas com sucesso!',
            'success',
          ).then(() => {
            this.loadingBottom = false
          })
        })
        .catch(() => {
          this.Swal.fire(
            'Error',
            'Não foi possível atualizar às fazendas, tente novamente!',
            'warning',
          ).then(() => {
            this.loadingBottom = false
          })
        })
    },
    selectAll() {
      this.farmsCods = this.farms
      this.selectedAll = true
      this.update = true
    },
  },
}
</script>
